// n is number of stars required
@function multiple-box-shadow ($n) 
  $value: '#{random(2000)}px #{random(500)}px #FFF'
  @for $i from 2 through $n
    $value: '#{$value} , #{random(2000)}px #{random(500)}px #FFF'

  @return unquote($value)

$shadows-small:  multiple-box-shadow(175)
$shadows-medium: multiple-box-shadow(50)
$shadows-big:    multiple-box-shadow(25)

// html
//   height: 100%
//   background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)
//   overflow: hidden
    
#stars
  width: 1px
  height: 1px
  background: transparent
  box-shadow: $shadows-small
  animation			: animStar 17s linear infinite
    
  &:after
    content: " "
    position: absolute
    top: 500px
    width: 1px
    height: 1px
    background: transparent
    box-shadow: $shadows-small
    
#stars2
  width: 2px
  height: 2px
  background: transparent
  box-shadow: $shadows-medium
  animation			: animStar 25s linear infinite
    
  &:after
    content: " "
    position: absolute
    top: 500px
    width: 2px
    height: 2px
    background: transparent
    box-shadow: $shadows-medium
    
#stars3
  width: 3px
  height: 3px
  background: transparent
  box-shadow: $shadows-big
  animation			: animStar 37s linear infinite
    
  &:after
    content: " "
    position: absolute
    top: 500px
    width: 3px
    height: 3px
    background: transparent
    box-shadow: $shadows-big


    
@keyframes animStar
  from	
    transform: translateY(0px)
  to		
    transform: translateY(-500px)
    